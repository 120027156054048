/*!
 * Stylesheets
 * Silence is gold!
 */

// Partials
@import "partials/_main";
@import "partials/_menu-structure";
@import "partials/_overlay-menu";
@import "partials/_menu";
@import "partials/_vertical-menu";
@import "partials/_centered-header";
@import "partials/_widgets";
@import "partials/_comments";
@import "partials/_shortcodes-style";
@import "partials/_module-share";
@import "partials/_side-navigation";
@import "partials/_boxed";
@import "partials/_single-post";
@import "partials/_related-posts";


// Modules and Variables
@import "modules/_light";
@import "modules/_dark";


// Third-party
@import "vendor/_woocommerce";
@import "vendor/_revslider";
@import "vendor/_woocommerce-product-filter";
@import "vendor/_woocommerce-stripe-gateway";
@import "vendor/_visual-composer";
@import "vendor/_ultimate-vc-addons";
@import "vendor/_contact-form-7";
@import "vendor/_bbpress";
@import "vendor/_timetable";
@import "vendor/_wp-hotel-booking";
@import "vendor/_wp-user-frontend-pro";
@import "vendor/_estatik";