.hotel-booking-search {
	.hb_input_field {
		width: 100%;
	}

	.hb-submit {
		@extend .wrapper;
		button {
			@extend .btn;
			@extend .btn-outline-secondary;
		}
	}
}

#ui-datepicker-div.ui-datepicker.ui-widget {
	width: 23.8em;
}