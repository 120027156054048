/*-----------------------------------------------------------------------------------*/
/*  *.  Comments
/*-----------------------------------------------------------------------------------*/

/* Avatar */

.comments-area ol.comment-list li img.avatar {
	border-radius: 100%;
	margin: 1em 1em 1em 0;
}

/* Comment List */

.comments-area ol.comment-list,
.comments-area ol.comment-list li ol.children {
	list-style-type:none;
}

.comments-area ol.comment-list li ol.children {
	padding-left: 2em;
	border-left: 1px solid #eee;
}

.comments-area ol.comment-list li.depth-1 {
    border-bottom: 1px solid #eee;
    padding-bottom: 2em;
    margin-bottom: 1em;
}

.comments-area ol.comment-list li.depth-1:last-child {
	border: none;
	padding-bottom: 0;
	margin-bottom: 5em;
}