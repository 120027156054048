#stripe-payment-data {

#wc-stripe-cc-form {
  display: flex;
  flex-direction: column;
  label {
    margin: 0 !important;
    font-size: 1rem !important;
  }
  .form-row {
    width: 100%;
    padding: 1em 1em 0 1em !important;
  }
  .wc-stripe-elements-field,
  .stripe-card-group {
    width: 100%;
  }
}

.woocommerce-SavedPaymentMethods-saveNew {
  display: block !important;
  input {
    margin: 0 !important;
  }
  label {
    font-size: 1rem !important;
  }
}

}