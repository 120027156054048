.es-powered {
  display: none;
}

.es-fixed {
  position: relative !important;
  z-index: auto !important;
  left: auto !important;
  width: auto !important;
}

.es-single .es-info .es-gallery .es-single-gallery-arrow.es-single-gallery-slick-next,
.es-single .es-info .es-gallery .es-single-gallery-arrow.es-single-gallery-slick-prev {
  z-index: auto !important;
}

.es-search__wrapper {
  background: transparent !important;
  padding: 0;
}

.es-search__wrapper .es-search__field .es-field__wrap .select2 *,
.es-search__wrapper .es-search__field .es-field__wrap input,
.es-search__wrapper .es-search__field .es-field__wrap select,
.es-search__wrapper,
.es-button {
  font-family: inherit;
}

.es-search__wrapper .es-search__field .es-field__wrap input,
.es-search__wrapper .es-search__field .es-field__wrap select {
  @extend .form-control;
  border-radius: .25rem;
}

.es-search__wrapper .es-search__field .es-field__wrap .select2 .select2-selection {
  border-radius: .25rem;
}

.es-search__wrapper .es-search__field .es-field__wrap .es-field__range input, .es-search__wrapper .es-search__field .es-field__wrap .es-field__range select,
.es-search__wrapper .es-search__field .es-field__wrap.es-field__wrap--units .es-field__range input, .es-search__field .select2-container .select2-selection--single {
  height: auto;
}

.es-search__wrapper .es-search__field .es-field__wrap .select2 .select2-selection--single .select2-selection__rendered {
  margin-bottom: 0;
  line-height: 1.5;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
}

.es-search__wrapper .es-search__field .es-field__wrap .select2 .select2-selection--multiple .select2-selection__rendered {
  margin-bottom: 0;
  line-height: 1.5;
  height: calc(1.5em + .75rem + 2px);
  padding: 0 .75rem;
}

.es-button-gray:hover {
  border: 1px solid #9e9e9e !important
}