/* Overlay Menu */

.desktop-mode {

	.navbar .navbar-nav li.dropdown a.dropdown-toggle i.dropdown-icon{
		transition: none;
		-moz-transition: none;
		-webkit-transition: none;
	}

	&.default-colors-overlay,
	&.light-colors-overlay,
	&.dark-colors-overlay {

		.hp-slider-revolution {
			top: 0;
			z-index: 1;
		}

		&.admin-bar .header-presentation {
			top: 32px;
		}

		.header-presentation {
			left: 0;
			right: 0;
			top: 0px;
		}

		.header-top {
			position: relative;
			z-index: 2;		
		}

	}

	&.colors-overlay-enabled .header-bottom {
		border-color: transparent !important;	
	}

	&.colors-overlay-enabled .header-top, &.colors-overlay-enabled .header-bottom {
		background-color: transparent !important;		
	}

	&.colors-overlay-disabled .header-bottom.dark {
		background-color: $darkColor;		
	}

	&.header-title-disabled-no-overlay .header-bottom.light,
	&.sticky-navigation-enabled .header-bottom.light,
	&.colors-overlay-disabled .header-bottom.light {
		background-color: #FFF;	
		border-color: #f0f2f7!important;
	}

	&.header-title-disabled-no-overlay .header-bottom.navbar-light,
	&.sticky-navigation-enabled .header-bottom.navbar-light {
		border-color: #f0f2f7!important;
	}


	&.colors-overlay-disabled .boxed-layout {

		nav.site-navigation {
			display: flex;
			justify-content: center;
		}

		.header-bottom.light,
		.header-bottom.dark {
			width: 1170px;		
		}

	}

	&.light-colors-nav {
		.header-top {
			border-color: #f0f2f7 !important;	
		}
	}

	&.dark-colors-nav {
		.header-top {
			border-color: rgba(255,255,255,.05) !important;	
			color: rgba(255,255,255,.5);
		}
	}

	&.light-colors-overlay { 
		.header-top {
			border-color: rgba(255, 255, 255, 0.1) !important;		
			.navbar-nav li a {
				color: rgba(255, 255, 255, 0.9) !important;

				&:hover{
					color: rgba(255, 255, 255, 0.7) !important;
				}
			}
		}
		&.colors-overlay-enabled .navbar .navbar-nav li:hover a,
		&.colors-overlay-enabled .navbar .navbar-nav .active > a {
			background-color: transparent !important;
		}
		&.colors-overlay-enabled .navbar .navbar-nav li.dropdown:hover a.dropdown-toggle{
			color: rgba(255, 255, 255, 0.7) !important;
		}
		&.colors-overlay-enabled .navbar .navbar-brand, 
		&.colors-overlay-enabled .navbar .navbar-nav li a,
		&.colors-overlay-enabled .navbar .navbar-nav>li>a, 
		&.colors-overlay-enabled .navbar .navbar-nav li.dropdown a.dropdown-toggle,
		.vslmd-widget-container li a:hover, 
		.vslmd-widget-container li span,
		.vslmd-widget-container li a, 
		.vslmd-widget-container li span i {
			color: rgba(255, 255, 255, 0.9) !important;

			&:hover{
				color: rgba(255, 255, 255, 0.7) !important;
			}
		}

		&.colors-overlay-enabled .navbar-nav .dropdown-menu.dropdown-menu-light .nav-link,
		&.colors-overlay-enabled .navbar-nav .dropdown-menu.dropdown-menu-white .nav-link {
			color: rgba(0,0,0,.5) !important;

			&:hover{
				color: rgba(0,0,0,.7) !important;
			}
		}
	}

	&.dark-colors-overlay { 
		.header-top {
			border-color: rgba(0, 0, 0, 0.1) !important;	
			.navbar-nav li a {
				color: rgba(0, 0, 0, 0.9) !important;

				&:hover{
					color: rgba(0, 0, 0, 0.7) !important;
				}
			}	
		}
		&.colors-overlay-enabled .navbar .navbar-nav li:hover a,
		&.colors-overlay-enabled .navbar .navbar-nav .active > a {
			background-color: transparent !important;
		}
		&.colors-overlay-enabled .navbar .navbar-nav li.dropdown:hover a.dropdown-toggle{
			color: rgba(0, 0, 0, 0.7) !important;
		}
		&.colors-overlay-enabled .navbar .navbar-brand, 
		&.colors-overlay-enabled .navbar .navbar-nav>li>a, 
		&.colors-overlay-enabled .navbar .navbar-nav li.dropdown a.dropdown-toggle,
		.vslmd-widget-container li a:hover, 
		.vslmd-widget-container li span,
		.vslmd-widget-container li a, 
		.vslmd-widget-container li span i {
			color: rgba(0, 0, 0, 0.9) !important;

			&:hover{
				color: rgba(0, 0, 0, 0.7) !important;
			}
		}
	}

}

.mobile-mode {

	&.header-title-disabled-no-overlay .header-bottom.light,
	&.sticky-navigation-enabled .header-bottom.light,
	&.colors-overlay-disabled .header-bottom.light,
	&.header-title-disabled-no-overlay .header-bottom.navbar-light,
	&.sticky-navigation-enabled .header-bottom.navbar-light {
		border-bottom: 1px solid #f0f2f7!important;
	}

}