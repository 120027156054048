/*-----------------------------------------------------------------------------------*/
/*  *.  Centered Header
/*-----------------------------------------------------------------------------------*/

.desktop-mode {

  /* Centered Style */

  .centered-header .centered-header-menu {

    display: grid !important;
    grid-template-columns: 1fr auto 1fr;

    .navbar-header .navbar-brand {
      margin: 0 1em;
      padding: 0;
    }

  }

  /* Centered Header With Under Menu */

  .centered-under {

    .navbar-collapse.collapse.centered-header-menu-under {

      .centered-header-top > .col-md-4.col-md-offset-4,
      .centered-header-bottom {
        display: flex;
        justify-content: center;
      }

    }

  }

}