/*-----------------------------------------------------------------------------------*/
/*  *.  Post Type Share
/*-----------------------------------------------------------------------------------*/

.desktop-mode {	

	.post-type-share .row {
	  margin: 0;
	  div ul {
		list-style: none;
		margin: 0;
		li {
		  display: inline;
		  i {
			font-size: 3rem;
			margin: 0 0.125em;
			&:hover {
			  transform: scale(1.2);
			}
		  }
		}
	  }
	}

}



/* Disable WhatsApp button on Desktop - Tutorial link: http://vslmd.me/1VIxAsz */
@media screen and (min-width: 1024px) {
    .vslmd-whatsapp {
	display: none !important;
    }
}
 
.vslmd-link {
    padding: 4px 8px !important;
    color: white;
    font-size: 2rem;
    border-radius: 3px;
    margin-right: 2px;
    cursor: pointer;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    margin-top: 2px;
    display: inline-block;
    text-decoration: none;
}

.vslmd-linkedin i:hover,.vslmd-linkedin i:active, 
.vslmd-googleplus i:hover,.vslmd-googleplus i:active,
.vslmd-facebook i:hover,.vslmd-facebook i:active,
.vslmd-twitter i:hover,.vslmd-twitter i:active {
    color: #363636;
	transform: scale(1.2);
}
 
.vslmd-social {
    margin: 20px 0px 25px 0px;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
}