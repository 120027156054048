/*-----------------------------------------------------------------------------------*/
/*  *.  Related Posts
/*-----------------------------------------------------------------------------------*/

.visualmodo-related-posts {	
  .card-columns {
    margin-bottom: 30px;
    .card { 
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
      transform: translate3d(0,0,0);
      -webkit-transform: translate3d(0,0,0);
      -webkit-transition: 0.3s;
      transition: 0.3s;
      border: none;
      border-radius: 0;
      &:hover {
        box-shadow: 0 22px 43px rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0 22px 43px rgba(0, 0, 0, 0.15);
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
        -moz-transform: none;
      }
      a { 
        img {
          border-radius: 0;
        }
        .card-body { 
          h6 { 
            color: #222328;
          }
          p{
            margin: 0;
            color: initial;
            line-height: normal;
          }
        }
      }
    }
  }
}