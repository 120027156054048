/*-----------------------------------------------------------------------------------*/
/*  *.  Reset
/*-----------------------------------------------------------------------------------*/

img {
  height: auto;
  vertical-align: middle;
}

a, h1, h2, h3, h4, h5, h6, p, span, i, .btn, figure, figcaption {
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}

a {
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: none;
  }
}

a:focus, a:hover {
  color: $darkColor;  
}

p {
  margin: 0 0 1.75rem;
}

dd {
  margin: 0 0 1.75em;
}

blockquote {
  font-style: italic;
  padding: .6em 1.2em;
  font-size: 1.1rem;
  border-left: 5px solid #eee;
  margin: 0 0 1.4em;
  p {
    margin: 0;
  }
}

.entry-summary h2, 
.comment-content h2 {
  margin-top: 2em;
  margin-bottom: 1em;
}

h2.entry-title a {
  color: $brand-primary;
  font-weight: 700;
  &:hover {
    color: $darkColor;
  }
}

ol {
  margin: 0 0 1.75em 1.25em;
  padding: 0;
}

ul {
  margin: 0 0 1.75em 1.25em;
  padding: 0;
  list-style: disc;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 1.75em;
  table-layout: fixed;
  width: 100%;
  border-left: 1px;
  border-top: 1px;
  border-style: solid;
  border-color: #d1d1d1;
}

td {
  border-left: 1px;
  border-top: 1px;
  border-style: solid;
  border-color: #d1d1d1;
}

th {
  border-left: 1px;
  border-top: 1px;
  border-style: solid;
  border-color: #d1d1d1;
  padding: 0.4375em;
}

td {
  padding: 0.4375em;
}

th {
  border-width: 0 1px 1px 0;
  font-weight: 700;
}

td {
  border-width: 0 1px 1px 0;
}

caption, th, td {
  text-align: left;
}

.form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}

.btn-primary {
  
  &:hover,
  &:focus,
  &:active {
    background-color: $darkColor;
    border-color: $darkColor;
  }
  
}

.navbar {
  z-index: 999;
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Header - Alert Message
/*-----------------------------------------------------------------------------------*/

.alert-message {
  z-index: 3;
  position: relative;
  .alert {
    border: none;
    border-radius: 0;
    margin-bottom: 0;
    .close {
      text-shadow: none;
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Page Header
/*-----------------------------------------------------------------------------------*/

.page-header {
  padding-bottom: 1em;
  margin: 2em 0 2em;
  border-bottom: 1px solid #ced4da;
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Entry Title
/*-----------------------------------------------------------------------------------*/

#archive-wrapper .entry-title,
#wrapper-index .entry-title {
  margin-top: 1em;
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Brand - Logos
/*-----------------------------------------------------------------------------------*/

.desktop-mode {
  .navbar-brand > img {
    &.brand-mobile, &.brand-light, &.brand-dark {
      display: none !important;
    }
  }
  &.light-colors-overlay .navbar-brand > img {
    &.brand-light {
      display: inline !important;
    }
    &.brand-default {
      display: none;
    }
  }
  &.dark-colors-overlay .navbar-brand > img {
    &.brand-dark {
      display: inline !important;
    }
    &.brand-default {
      display: none;
    }
  }
  &.colors-overlay-disabled.light-colors-overlay .header-bottom .navbar-brand > img.brand-light, 
  &.colors-overlay-disabled.dark-colors-overlay .header-bottom .navbar-brand > img.brand-dark {
    display: none !important;
  }
  &.colors-overlay-disabled .header-bottom .navbar-brand > img.brand-default {
    display: inline;
  }
}

.mobile-mode {
  .navbar-brand > img {
    height: 100%;
    &.brand-default, &.brand-light, &.brand-dark {
      display: none !important;
    }
  }
}

/* Retina Logo Ready */

.brand-retina.navbar-brand img {
  height: 50%;
  max-width: none;
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Header Presentation
/*-----------------------------------------------------------------------------------*/

.header-presentation {
  .hp-scroll-down {
    margin-top: -2em;
    font-size: 4rem;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
  .hp-background-color {
    &.preset-color-layout-header-title-mode-3 {
      background-color: rgba(0,0,0,0.3)
    }
    background-color: #EFEFEF;
    height: inherit;
    .container {
      display: table;
      height: 100%;
      .hp-content {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        h1 {
          color: #FFF;
          font-size: 2.5rem;
        }
        p {
          color: rgba(255, 255, 255, 0.8);
          font-size: 1.25rem;
        }
        h1, p {
          line-height: 1.4;
        }
      }
    }
  }
}

.desktop-mode .header-presentation .hp-background-color .container .hp-content {
  h1 {
    font-size: 3.5rem;
  }
  p {
    font-size: 1.5rem;
  }
}

.mobile-mode {
  #page-wrapper,
  .header-presentation,
  .hp-slider-revolution {
    margin-top: 0 !important;
  }
}

.header-presentation {
  &.simple-slider .carousel-indicators li {
    border: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0;
    width: 30px;
    height: 3px;
    margin: 0 3px;
    transition: all .3s;
    &.active, &:hover {
      background-color: #FFF;
    }
  }
  .carousel-inner .item .carousel-caption {
    text-shadow: none;
    bottom: 50%;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -ms-transform: translateY(50%);
    -o-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
    transition: opacity .3s ease-in-out;
    h1 {
      color: #FFF;
      font-size: 2.5rem;
    }
    p {
      color: rgba(255, 255, 255, 0.8);
      font-size: 1.25rem;
    }
    h1, p {
      line-height: 1.4;
    }
  }
}

.desktop-mode .header-presentation .carousel-inner .item .carousel-caption {
  h1 {
    font-size: 5rem;
  }
  p {
    font-size: 2.5rem;
  }
}

.header-presentation .carousel-inner .item.active .carousel-caption {
  opacity: 1;
}


/*-----------------------------------------------------------------------------------*/
/*  *.  Sidebar
/*-----------------------------------------------------------------------------------*/

.widget-area {
  
  .widget-title,
  aside.widget h4 {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.075rem;
    margin-bottom: 2rem;
    font-weight: bold;
    color: rgba(0,0,0,0.6);
  }
  
  aside.widget ol, 
  aside.widget ul {
    margin: 0 0 3.5em;
    padding: 0;
    list-style: none;
    
    li {
      padding: 0.250em 0;
      
      a {
        color: $darkColor;
        
        &:hover {
          color: $brand-primary;
        }
      }
    }
  }
  
  
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Tag Cloud
/*-----------------------------------------------------------------------------------*/

.tagcloud a {
  font-size: 1em !important;
  padding: 4px 8px;
  vertical-align: middle;
  background-color: $darkColor;
  color: #FFF;
  border-radius: $btn-border-radius-base;
  margin-bottom: 4px;
  display: inline-block;
  
  &:hover {
    background-color: $brand-primary;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Nav Search & Widget Search
/*-----------------------------------------------------------------------------------*/

li:not(.nav-search).nav-search #searchsubmit,
aside.widget_search {
  margin-bottom: 3.5em;
  #searchsubmit {
    border-radius: 0 .25rem .25rem 0;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Widget Flickr
/*-----------------------------------------------------------------------------------*/

.flickr-badges {
  
  .flickr_badge_image {
    margin: 0 10px 10px 0;
    width: 72px;
    height: 72px;
    float: left;
    background: 0 0;
    padding: 0;
    
    a {
      border: none;
      
      img {
        margin: 0;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
      }
      
    }
    
  }
  
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Footer
/*-----------------------------------------------------------------------------------*/

.wrapper-footer {
  padding: 2.5em 0;
  .widget-footer-title {
    text-transform: uppercase;
    font-size: 1rem;
    letter-spacing: 0.075rem;
    margin-bottom: 2rem;
    font-weight: bold;
  }
  .widgets-footer {
    border-bottom: 1px solid;
    padding-bottom: 2.25em;
    margin-bottom: 2.25em;
    ol, ul {
      margin: 0 0 1.75em;
      padding: 0;
      list-style: none;
    }
    ol li, ul li {
      padding: 0.250em 0;
    }
    ol li a, ul li a {
      color: #FFF;
    }
    ol li a:hover, ul li a:hover {
      color: $brand-primary;
    }
  }
}

.footer-bg-dark {
  background-color: #343a40;
}

.footer-bg-light {
  background-color: #f8f9fa;
}

.footer-bg-white {
  background-color: #FFF;
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Breadcrumbs & Bottom to Top Footer
/*-----------------------------------------------------------------------------------*/

.top-footer {
  border-bottom: 1px solid;
  padding-bottom: 2.25em;
  margin-bottom: 2.25em;
  .breadcrumbs-footer {
    padding: 0;
    .breadcrumb {
      padding: 0;
      margin: 0;
      > li + li {
        &:before {
          display: block;
          content: " ";
          float: right;
          border-style: solid;
          border-width: 5px 0 5px 5px;
          margin: 7px 0 0 10px;
        }
        &:last-child:before {
          display: none;
        }
      }
    }
    ol.breadcrumb {
      i {
        color: #FFF !important;
      }
      li {
        margin: 0 10px;
        &:first-child {
          margin: 0 10px 0 0;
        }
      }
    }
  }
  .bottom-to-top {
    padding: 0;
    span i {
      font-size: 1.45em;
      cursor: pointer;
    }
  }
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Extra Elements Nav 
/*-----------------------------------------------------------------------------------*/

.extra-elements-nav {
  ul {
    flex-direction: row;
  }
}