/*------------------------------------------------------

CSS overview: 

	1. Icon
	
---------------------------------------------------------*/


/*--------------------------------------------------
				      ICON
---------------------------------------------------*/


.circle,
.square,
.rounded,
.outline_circle,
.outline_square,
.outline_rounded {
	text-align: center;
	border: 2px solid transparent;
}

.circle,
.outline_circle {
	border-radius: 50%;	
}

.rounded,
.outline_rounded {
	border-radius: 5px;	
}