.wpuf-dashboard-container {
	display: flex;
}

.wpuf-fields {
	input,
	select,
	textarea {
		@extend .form-control;
	}
}

#wpuf-user_login,
#wpuf-user_pass {
	@extend .form-control;
}

.field-size-small .wpuf-fields {
	width: 100% !important;
}

ul.wpuf-form .wpuf-el {
	display: block;
}

#wp-submit,
.wpuf-submit-button,
#wpuf-account-update-profile,
#wpuf-account-update-billing_address {
	@extend .btn;
	@extend .btn-secondary;
}

.pass-strength-result {
	@extend .alert;
	@extend .alert-secondary;
	width: unset;
}

.wpuf-info,
.wpuf-message {
	@extend .alert;
	@extend .alert-warning;
}

.wpuf-category-checklist {
	display: flex;
	flex-wrap: wrap;
	li.wpuf-checkbox-inline {
		margin-right: 1em;
		margin-bottom: 0;
		padding: 0;
		label.selectit {
			display: flex;
			align-items: center;
			input {
				height: auto;
				width: auto;
				margin-right: 0.5em;
			}
		}
	}
}