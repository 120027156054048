/*-----------------------------------------------------------------------------------*/
/*  *.  Mega Menu
/*-----------------------------------------------------------------------------------*/

.desktop-mode {	

  .dropdown.mm {
    &.boxed .dropdown-menu li.dropdown-submenu {
     display: table-cell;
   }
   > ul.dropdown-menu > li > a {
    font-weight: 700;
    background: transparent;
  }
  ul.dropdown-menu li.dropdown-submenu {
    display: inline-block;
    width: 25%;
    padding: 1em;
    margin-right: -0.5%;
    a {
      background-color: transparent;
    }
    > a:after {
      display: none;
    }
    ul.dropdown-menu {
      background-color: transparent;
      position: static !important;
      display: block;
      opacity: 1;
    }
  }
  &.right .dropdown-menu {
    left: 0;
    right: auto;
  }
  &.wide {
    position: static;
    ul.dropdown-menu {
      @extend .container;
      padding: 0 !important;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  &.mm-one-full ul.dropdown-menu li.dropdown-submenu {
    width: 100%;
  }
  &.mm-one-half ul.dropdown-menu li.dropdown-submenu {
    width: 50%;
  }
  &.mm-one-third ul.dropdown-menu li.dropdown-submenu {
    width: 33.33333333%;
  }
  &.mm-one-fourth ul.dropdown-menu li.dropdown-submenu {
    width: 25%;
  }
  &.mm-one-sixth ul.dropdown-menu li.dropdown-submenu {
    width: 16.66666667%;
  }
}

.dropdown.sidebar-dropdown-menu ul.dropdown-menu li.dropdown-submenu aside {
	width: 100%;
}

}

/*-----------------------------------------------------------------------------------*/
/*  *.  Mega Menu Vertical
/*-----------------------------------------------------------------------------------*/

.desktop-mode { 

  .vertical-header {

    .dropdown.mm {

      &.wide {
        position: relative;
      }

    }

  }

}

/*-----------------------------------------------------------------------------------*/
/*  *.  Sticky Navigation
/*-----------------------------------------------------------------------------------*/

.sticky-navigation {
  position: sticky;
  z-index: 999;
  top: 0;
}

.desktop-mode.admin-bar .sticky-navigation {
  position: sticky;
  top: 32px;
  z-index: 999;
}

@media screen and (max-width: 600px) {
  .mobile-mode.admin-bar .sticky-navigation {
    top: 0px !important;
  }
}

.mobile-mode {
  &.admin-bar .sticky-navigation {
    position: sticky;
    top: 46px;
  }
  .sticky-navigation {
    position: sticky;
    top: 0px;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Change Click Submenu to Hover
/*-----------------------------------------------------------------------------------*/

.desktop-mode {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Item Description
/*-----------------------------------------------------------------------------------*/

.desktop-mode {
  .header-bottom {
    &.header-large {
      .navbar-nav li a .item-description {
        display: block;
        font-size: 0.8rem;
        line-height: 3em;
        margin-top: -60px;
      }
      .dropdown-menu > li > a .item-description {
        margin: 0;
        line-height: 1em;
        margin-top: 0.5em;
      }
    }
    &.header-medium {
      .navbar-nav li a .item-description {
        display: block;
        font-size: 0.8rem;
        line-height: 3em;
        margin-top: -45px;
      }
      .dropdown-menu > li > a .item-description {
        margin: 0;
        line-height: 1em;
        margin-top: 0.5em;
      }
    }
    &.header-small {
      .navbar-nav li a .item-description {
        display: block;
        font-size: 0.8rem;
        line-height: 3em;
        margin-top: -30px;
      }
      .dropdown-menu > li > a .item-description {
        margin: 0;
        line-height: 1em;
        margin-top: 0.5em;
      }
    }
  }
}

.mobile-mode {
  .item-description {
    display: none;
  }
}

.title-content {
  display: inline-table;
}
/*-----------------------------------------------------------------------------------*/
/*  *.  Icon Menu
/*-----------------------------------------------------------------------------------*/

.icon-title,
.icon-title-description {
  margin-right: 0.5em;
  font-size: 1rem;
}

.desktop-mode {
  nav.navbar-expand-sm.header-bottom .dropdown-menu>li>a {
    display: flex;
    align-items: center;
  }
}

.mobile-mode {
  nav.navbar-expand-sm.header-bottom .dropdown-menu {
    background-color: transparent;
  }
}

.desktop-mode {
  nav.navbar-expand-md.header-bottom .dropdown-menu>li>a {
    display: flex;
    align-items: center;
  }
}

.mobile-mode {
  nav.navbar-expand-md.header-bottom .dropdown-menu {
    background-color: transparent;
  }
}

.desktop-mode {
  nav.navbar-expand-lg.header-bottom .dropdown-menu>li>a {
    display: flex;
    align-items: center;
  }
}

.mobile-mode {
  nav.navbar-expand-lg.header-bottom .dropdown-menu {
    background-color: transparent;
  }
}

.desktop-mode {
  nav.navbar-expand-xl.header-bottom .dropdown-menu>li>a {
    display: flex;
    align-items: center;
  }
}

.mobile-mode {
  nav.navbar-expand-xl.header-bottom .dropdown-menu {
    background-color: transparent;
  }
}


/*-----------------------------------------------------------------------------------*/
/*  *.  Extra Items Menu
/*-----------------------------------------------------------------------------------*/

.extra-md-menu {
	width: 25rem;
	padding: 25px !important;
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Multi Level Horizontal Menu
/*-----------------------------------------------------------------------------------*/

.desktop-mode .header-bottom .dropdown:not(.mm).menu-item-has-children .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.desktop-mode .header-bottom .dropdown:not(.mm).menu-item-has-children li.dropdown-submenu>.dropdown-menu {
  display: none;
}

.dropdown-submenu {
  position: relative;
}

.mobile-mode li:not(.mm).dropdown.show ul.dropdown-menu,
.mobile-mode li:not(.mm).dropdown.show ul.dropdown-menu li.dropdown-submenu {
  display: block;
}

.mobile-mode .mm .dropdown-submenu.show-submenu > .dropdown-menu {
  display: block;
}

.dropdown-menu {
  box-shadow: none;
  border-radius: 0;
  border: none;
}

.dropdown-submenu {
  > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
  }
  &:hover {
    > a:after {
      border-left-color: #fff;
    }
  }
  > a:after {
    position: absolute;
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f105";
    right: 1.5rem;
  }
  &.pull-left {
    float: none;
    > .dropdown-menu {
      left: -100%;
      margin-left: 10px;
    }
  }
}

.desktop-mode {
  .dropdown-submenu {
    > .dropdown-menu {
      display: block;
      opacity: 0;
      -webkit-transition: all 200ms ease-in;
      -moz-transition: all 200ms ease-in;
      -ms-transition: all 200ms ease-in;
      -o-transition: all 200ms ease-in;
      transition: all 200ms ease-in;
    }
    &:hover > .dropdown-menu {
      display: block;
      opacity: 1;
    }
  }
  .admin-bar .navbar-fixed-top {
    top: 32px;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Dropdown Menu Colored
/*-----------------------------------------------------------------------------------*/


//With Background Color Default

.desktop-mode nav:not(.navbar-custom) .navbar-nav .dropdown-menu.dropdown-menu-light {
  @extend .bg-light;
  .nav-link {
    color: rgba(0,0,0,.5) !important;
    &:hover, &:focus, &:active {
      color: rgba(0,0,0,.7) !important;
      background-color: transparent;
    }
  }
}

.desktop-mode nav:not(.navbar-custom) .navbar-nav .dropdown-menu.dropdown-menu-dark {
  @extend .bg-dark;
  .nav-link {
    color: rgba(255,255,255,.5) !important;
    &:hover, &:focus, &:active {
      color: rgba(255,255,255,.75) !important;
      background-color: transparent;
    }
  }
}

//Without Background Color Default

.desktop-mode nav:not(.navbar-custom) .navbar-nav .dropdown-menu.dropdown-menu-light-no-bg .nav-link {
  color: rgba(0,0,0,.5) !important;
  &:hover, &:focus, &:active {
    color: rgba(0,0,0,.7) !important;
    background-color: transparent;
  }
}

.desktop-mode nav:not(.navbar-custom) .navbar-nav .dropdown-menu.dropdown-menu-dark-no-bg .nav-link {
  color: rgba(255,255,255,.5) !important;
  &:hover, &:focus, &:active {
    color: rgba(255,255,255,.75) !important;
    background-color: transparent;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  *.  Remove Default Bootstrap Caret of the Menu
/*-----------------------------------------------------------------------------------*/

.dropdown-toggle::after, .woocommerce .variations td.value select::after {
  display: none;
}