.bbpress {
	
	/* Breadcrumb */
	.bbp-breadcrumb p,
	.bbp-topic-tags p {
		@extend .breadcrumb;
	}
	/* Subscribe */
	#bbpress-forums > #subscription-toggle span a.subscription-toggle {
		@extend .btn;
		@extend .btn-secondary;
		float: right;
	}
	
	/* Search */
	.bbp-search-form form > div {
		@extend .input-group; 
	}
	.bbp-search-form form input.button {
		@extend .btn; 
		@extend .btn-primary;
		z-index: 2;
    	margin-left: -1px;
		border-bottom-left-radius: 0;
    	border-top-left-radius: 0;
	}
	.bbp-search-form form input#bbp_search {
		@extend .form-control;
		border-bottom-left-radius: 4px !important;
    	border-top-left-radius: 4px !important;
	}
	
	/* Forum & Topic */
	#bbpress-forums li.bbp-body ul.forum, 
	#bbpress-forums li.bbp-body ul.topic,
	li.bbp-body div.hentry {
		padding: 3em;
	}
	#bbpress-forums li.bbp-header {
		background: #FFF;
		border-top: none;
	}
	div.bbp-reply-header {
		padding: 1em 2em 1em 5em;
	}
	#bbpress-forums li.bbp-header, 
	#bbpress-forums li.bbp-footer {
		text-transform: uppercase;
		font-size: 1rem;
		color: #CCC;
		padding: 2em;
		font-weight: 900;
	}
	#bbpress-forums div.odd, 
	#bbpress-forums ul.odd {
		background: #FFF;
	}
	a.bbp-forum-title,
	a.bbp-topic-permalink {
		font-size: 1.1rem;
	}
	
	/* Alerts */
	div.bbp-template-notice {
		padding: 15px;
		margin-bottom: 20px;
		border: 1px solid transparent;
		border-radius: 4px;
		color: #8a6d3b;
		background-color: #fcf8e3;
		border-color: #faebcc;
	}
	div.bbp-template-notice.info {
		color: #31708f;
		background-color: #d9edf7;
		border-color: #bce8f1;	
	}
	
	/* Style form elements */
	#bbpress-forums fieldset.bbp-form input#bbp_topic_tags,
	#bbpress-forums fieldset.bbp-form input#bbp_topic_title,
	#bbpress-forums fieldset.bbp-form select#bbp_stick_topic_select,
	#bbpress-forums fieldset.bbp-form select#bbp_topic_status_select,
	#bbpress-forums div.bbp-the-content-wrapper textarea.bbp-the-content {
		@extend .form-control;
	}
	#bbpress-forums div.bbp-the-content-wrapper textarea.bbp-the-content {
		border-top-left-radius: 0px;
    	border-top-right-radius: 0px;
	}
	#bbpress-forums fieldset.bbp-form input#bbp_topic_subscription {
		@extend .form-check;
		@extend .form-check-inline;
	}
	#bbpress-forums fieldset.bbp-form .bbp-submit-wrapper button#bbp_topic_submit,
	#bbpress-forums fieldset.bbp-form .bbp-submit-wrapper button#bbp_reply_submit {
		@extend .btn;
		@extend .btn-success;
	}
	#bbpress-forums div.bbp-the-content-wrapper div.quicktags-toolbar input {
		@extend .btn;
		@extend .btn-primary;
		@extend .btn-sm;
		box-shadow: none;
	}
	
	/* Avatar */
	#bbpress-forums p.bbp-topic-meta img.avatar, 
	#bbpress-forums ul.bbp-reply-revision-log img.avatar, 
	#bbpress-forums ul.bbp-topic-revision-log img.avatar, 
	#bbpress-forums div.bbp-template-notice img.avatar, 
	#bbpress-forums .widget_display_topics img.avatar,
	#bbpress-forums .widget_display_replies img.avatar {
		margin-bottom: 0;
		border: none;
		border-radius: 100%;
	}
	#bbpress-forums div.bbp-forum-author img.avatar, 
	#bbpress-forums div.bbp-topic-author img.avatar, 
	#bbpress-forums div.bbp-reply-author img.avatar {
		border-radius: 100%;
	}
	
}