/* Side Navigation */

.light.side-navigation {
  background-color: transparent;
  i, a, p, span, h1, h2, h3, h4, h5, h6, div, ul, li, ol {
    color: #35373A;
    list-style: none;
    transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
  }
  i:hover, a:hover {
    color: $darkColorRgba;
  }
  .navbar-nav li {
    a {
      &:hover, &:active, &:focus {
        background-color: transparent;
      }
      div.title-content span.item-description {
        color: $darkColorRgba;
      }
    }
    &:hover a {
      div, span {
        color: $darkColorRgba;
      }
    }
  }
  .side-navigation-inner {
    background-color: #F2F2F2;
  }
}

/* Light Contact Information Widget */

.desktop-mode,
.mobile-mode {
  &.light-colors-overlay {
    .contat-information-widget.contact-information-light {
      li span,
      li span i {
        color: $darkColor !important;
      }
    }
  }
}

.desktop-mode,
.mobile-mode {
  .contat-information-widget.contact-information-light {
    li span,
    li span i {
      color: $darkColor !important;
    }
  }
}

/* Light Social Icons Widget */

.wrapper-footer.light .social-widget-icon a i { /* Deprecated. Keep only for compatibility. */
  color: $darkColor;
}

.social-icons-widget.social-icons-light ul li.social-widget-icon a i {
  color: $darkColor;
}

/* Light Footer */


.wrapper-footer.light {
  .widget-footer-title {
    color: $darkColorRgba;
  }
  .widgets-footer {
    color: $darkColor;
    border-bottom-color: rgba(0, 0, 0, 0.1);
    ol li a, ul li a {
      color: $darkColor;
    }
    ol li a:hover, ul li a:hover {
      color: $brand-primary;
    }
  }
}

.light footer .site-info.bottom-footer {
  color: $darkColorRgba;
}

/* Light Footer > Breadcrumb */


.light .top-footer {
  border-bottom-color: rgba(0, 0, 0, 0.1);
  .breadcrumbs-footer .breadcrumb {
    background-color: transparent;
    span.current {
      color: $darkColorRgba;
    }
    a,span a {
      color: $darkColor;	
    } 
    i {
      color: $darkColor !important;  
    } 
  }
  .bottom-to-top span i {
    color: $darkColorRgba;
    &:hover {
      color: $darkColor;
    }
  }
}