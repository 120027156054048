@media (max-width: 576px) {
  .responsive-collapsing-sm {
    #single-wrapper .col-md-8.content-area {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
  }
}

@media (max-width: 768px) {
  .responsive-collapsing-md {
    #single-wrapper .col-md-8.content-area {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
  }
}

@media (max-width: 992px) {
  .responsive-collapsing-lg {
    #single-wrapper .col-md-8.content-area {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
  }
}

@media (max-width: 1200px) {
  .responsive-collapsing-xl {
    #single-wrapper .col-md-8.content-area {
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      order: -1;
    }
  }
}