.smile-icon-timeline-wrap,
.smile_icon_list li .icon_list_icon,
.ult_dual_button .middle-text,
.ult-banner-block.ult-bb-box a.bb-link,
.ult-banner-block h3.bb-top-title,
.ulimate-call-to-action-link,
.ult-ih-item.ult-ih-circle.ult-ih-effect16 .ult-ih-image-block,
.ult-ih-item.ult-ih-circle.ult-ih-effect3 .ult-ih-image-block,
.ult-ih-item.ult-ih-circle.ult-ih-effect10 .ult-ih-image-block,
.ult_featured .ult_pricing_table {
	z-index: 5 !important;
}