/*-----------------------------------------------------------------------------------*/
/*  *.  Vertical Menu
/*-----------------------------------------------------------------------------------*/

.desktop-mode.vertical-nav {
  .header-top {
    display: none !important;
  }
  &.admin-bar .vertical-header {
    top: 32px;
  }
  .vertical-header-widget-area {
    padding: 15px 30px;
  }
  .horizontal-header {
    display: none;
  }
  .vertical-header {
    position: fixed;
    width: 300px;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    .navbar-header {
      padding: 30px 30px 15px 30px;
    }
    .vertical-header-menu-container {
      width: 100%;
    }
    .vertical-header-menu {
      width: 100%;
      > li > a {
        padding: 10px 45px;
      }
    }
    .navbar-collapse {
      padding: 0;
    }
    .dropdown-menu {
      position: absolute !important;
      border: none;
      top: 0;
      left: 300px;
      min-width: 225px;
      > li > a {
        padding: 1em 1.5em !important;
      }
    }
    &.navbar-default {
      background-color: #FFF;
      border-color: transparent;
    }
    nav.site-navigation div.navbar {
      border: none;
      background-color: transparent;
      padding: 0;
      .navbar-nav {
        margin: 15px 0;
        > li {
          float: none;
        }
      }
    }
  }
}

.mobile-mode .vertical-header {
  display: none !important;
}

.desktop-mode.vertical-nav {
  .header-presentation .hp-background-color .container, #page-wrapper.wrapper .container, #woocommerce-wrapper .container, #single-wrapper .container, #wrapper-index.wrapper .container, #archive-wrapper.wrapper .container, .footer-background-image .wrapper-footer .container {
    width: 100%;
  }
}

@media (min-width: 991px) {
  .vertical-nav {
    .header-presentation .hp-background-color .container, #page-wrapper.wrapper .container, #woocommerce-wrapper .container, #single-wrapper .container, #wrapper-index.wrapper .container, #archive-wrapper.wrapper .container, .footer-background-image .wrapper-footer .container {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1500px) and (min-width: 991px) {
  .vertical-nav {
    .header-presentation .hp-background-color .container, #page-wrapper.wrapper .container, #woocommerce-wrapper .container, #single-wrapper .container, #wrapper-index.wrapper .container, #archive-wrapper.wrapper .container, .footer-background-image .wrapper-footer .container {
      width: 100%;
    }
  }
}

@media (min-width: 1501px) {
  .vertical-nav {
    .header-presentation .hp-background-color .container, #page-wrapper.wrapper .container, #woocommerce-wrapper .container, #single-wrapper .container, #wrapper-index.wrapper .container, #archive-wrapper.wrapper .container, .footer-background-image .wrapper-footer .container {
      width: 1170px;
    }
  }
}

/* Vertical Navigation Distance Between Menu and Content Control */

@media (min-width: 576px) {
  .vertical-nav.responsive-collapsing-sm {
    .header-presentation, #page-wrapper.wrapper, #woocommerce-wrapper.wrapper, #single-wrapper, #wrapper-index.wrapper, #archive-wrapper.wrapper, .footer-background-image, section.hp-slider-revolution .fullscreen-container, section.hp-slider-revolution .fullwidthbanner-container, .vc_row[data-vc-stretch-content=true] {
      padding-left: 300px !important;
    }
  }
}

@media (min-width: 768px) {
  .vertical-nav.responsive-collapsing-md {
    .header-presentation, #page-wrapper.wrapper, #woocommerce-wrapper.wrapper, #single-wrapper, #wrapper-index.wrapper, #archive-wrapper.wrapper, .footer-background-image, section.hp-slider-revolution .fullscreen-container, section.hp-slider-revolution .fullwidthbanner-container, .vc_row[data-vc-stretch-content=true] {
      padding-left: 300px !important;
    }
  }
}

@media (min-width: 992px) {
  .vertical-nav.responsive-collapsing-lg {
    .header-presentation, #page-wrapper.wrapper, #woocommerce-wrapper.wrapper, #single-wrapper, #wrapper-index.wrapper, #archive-wrapper.wrapper, .footer-background-image, section.hp-slider-revolution .fullscreen-container, section.hp-slider-revolution .fullwidthbanner-container, .vc_row[data-vc-stretch-content=true] {
      padding-left: 300px !important;
    }
  }
}

@media (min-width: 1200px) {
  .vertical-nav.responsive-collapsing-xl {
    .header-presentation, #page-wrapper.wrapper, #woocommerce-wrapper.wrapper, #single-wrapper, #wrapper-index.wrapper, #archive-wrapper.wrapper, .footer-background-image, section.hp-slider-revolution .fullscreen-container, section.hp-slider-revolution .fullwidthbanner-container, .vc_row[data-vc-stretch-content=true] {
      padding-left: 300px !important;
    }
  }
}