/* Widgets */

.vslmd-widget-container {
  display: table;
  ul {
    margin: 0;
    li {
      display: inline-block;
    }
  }
  &.widget-align-left {
    float: left;
  }
  &.widget-align-center {
    margin: auto;
  }
  &.widget-align-right {
    float: right;
  }
  li {
    span {
      display: block;
      font-size: 1rem;
      padding: 4px 10px;
      &:first-child {
        padding: 4px 10px 4px 0;
      }
      i {
        vertical-align: middle;
        margin-right: 5px;
        font-size: 1.4rem;
      }
    }
    a {
      display: block;
      font-size: 1.4rem;
      padding: 4px 8.4px;
    }
  }
}

/* Widget Menu */

.desktop-mode {

  ul#widget-menu {
    margin: 0 -30px;
    &.navbar-vertical {
      display: flex;
      flex-direction: column;
    }
 }

 .widget-area {

  #widget-menu.nav>li>a:focus, 
  #widget-menu.nav>li>a:hover {
    background-color: transparent;
  }

}

.widgets-footer {

  ul#widget-menu {
    margin: 0;
  }
  
}

}

.mobile-mode {

  ul#widget-menu {
    display: none;
  }

}

.wrapper-footer.footer-bg-dark {
  nav.navbar:not(.navbar-light) ul li a,
  nav.navbar:not(.navbar-dark) ul li a {
    color: $lightColorRgba;
  }
}

.wrapper-footer.footer-bg-light {
  nav.navbar:not(.navbar-light) ul li a ,
  nav.navbar:not(.navbar-dark) ul li a {
    color: $darkColorRgba;
  }
}

/* Widget Instagram */

.vslmd-instagram-pics li{
  padding: .25em !important;
}

/* Contat Information Widget */

.wrapper-footer.dark .site-info.bottom-footer .contat-information-widget li span,
.wrapper-footer.dark .site-info.bottom-footer .contat-information-widget li span i {
    color: rgba(255,255,255,.6);
}