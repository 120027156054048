.desktop-mode.vertical-nav {
  .hp-slider-revolution > {
    rs-module-wrap >,
    rs-fullwidth-wrap > {
      rs-module,
      rs-module-wrap {
        margin-left: 300px !important;
        width: calc(100% - 300px) !important;
      }
    }
  }
}