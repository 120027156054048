/* Menu */

.desktop-mode {

  .header-bottom {
    padding: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    position: relative;
    z-index: 2;
    .dropdown-menu {
      margin: 0;
      min-width: 14rem;
      padding: 0;
    }
  }

  .header-top {
    border: none;
    border-bottom: 1px solid transparent;
  }

  .navbar-light.header-bottom .dropdown-menu > li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .navbar-dark.header-bottom .dropdown-menu > li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }

  .header-bottom {
    .navbar-brand, .navbar-nav li a {
      padding-top: 0;
    }
    &.header-small {
      .navbar-brand, .navbar-nav li a {
        line-height: 60px;
        height: 60px;
      }
    }
    &.header-medium {
      .navbar-brand, .navbar-nav li a {
        line-height: 90px;
        height: 90px;
      }
    }
    &.header-large {
      .navbar-brand, .navbar-nav li a {
        line-height: 120px;
        height: 120px;
      }
    }
    .navbar-brand > img {
      display: inline;
      margin-top: -3px;
    }
    .mm .dropdown-menu > .dropdown-submenu {
      border-bottom: none;
    }
    .dropdown-menu > li {
      &:last-child {
        border-bottom: none;
      }
      > a {
        line-height: 1 !important;
        height: auto !important;
        padding: 1rem 1.5rem !important;
      }
    }
    .dropdown-submenu > .dropdown-menu {
      margin-top: 0;
    }
  }


}