/* Boxed Layout */

@media (min-width: 1200px) {

  #page.boxed-layout {
    width: 1170px;
    margin: 0 auto;
    overflow: hidden;
    #content.container {
      background-color: #FFF;
    }
  }

}