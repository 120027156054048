.wpb-js-composer {
	
	//Remove Padding Top/Bottom on page that contain VC activated
	.wrapper#page-wrapper {
		padding: 0;
	}

	//Fix Conflict Between Submenu And VC Column Inner
	.vc_row.vc_row-flex>.vc_column_container>.vc_column-inner {
		z-index: inherit;
	}

	//Remove Extra Read More Button
	.vc_grid-item .btn-read-more {
		display: none;
	}
}

.desktop-mode.vertical-nav .vc_row-fluid .vc_video-bg iframe {
    width: calc(100% - 300px) !important;
    margin-left: 300px !important;
}