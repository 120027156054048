/* Side Navigation */

.dark.side-navigation {
  background-color: transparent;
  i, a, p, span, h1, h2, h3, h4, h5, h6, div, ul, li, ol {
    color: #FFF;
    list-style: none;
    transition: all .2s linear;
    -moz-transition: all .2s linear;
    -webkit-transition: all .2s linear;
  }
  i:hover, a:hover {
    color: $lightColorRgba;
  }
  .navbar-nav li {
    a {
      &:hover, &:active, &:focus {
        background-color: transparent;
      }
      div.title-content span.item-description {
        color: $lightColorRgba;
      }
    }
    &:hover a {
      div, span {
        color: $lightColorRgba;
      }
    }
  }
  .side-navigation-inner {
    background-color: $darkColor;
  }
}

/* Dark Contact Information Widget */

.desktop-mode,
.mobile-mode {
  &.dark-colors-overlay {
    .contat-information-widget.contact-information-dark {
      li span,
      li span i {
        color: #FFF !important;
      }
    }
  }
}

.desktop-mode,
.mobile-mode {
  .contat-information-widget.contact-information-dark {
    li span,
    li span i {
      color: #FFF !important;
    }
  }
}

/* Dark Social Icons Widget */

.wrapper-footer.dark .social-widget-icon a i { /* Deprecated. Keep only for compatibility. */
  color: #FFF;
}

.social-icons-widget.social-icons-dark ul li.social-widget-icon a i {
  color: #FFF;
}

/* Dark Footer */


.wrapper-footer.dark {
  p {
  	color:#FFF;
  }
  .widget-footer-title {
    color: $lightColorRgba;
  }
  .widgets-footer {
    color: #FFF;
    border-bottom-color: rgba(255, 255, 255, 0.1);
    ol li a, ul li a {
      color: #FFF;
    }
    ol li a:hover, ul li a:hover {
      color: $brand-primary;
    }
  }
}

.wrapper-footer.dark .site-info.bottom-footer, {
  color: $lightColorRgba;
}


/* Dark Footer > Breadcrumb */


.dark .top-footer {
  border-bottom-color: rgba(255, 255, 255, 0.1);
  .breadcrumbs-footer .breadcrumb {
    background-color: transparent;
    span.current {
      color: $lightColorRgba;
    }
    a,i,span a, .breadcrumb-item {
     color: #FFF;	
  } 
}
.bottom-to-top span i {
  color: $lightColorRgba;
  &:hover {
    color: #FFF;
  }
}
}