/*-------------------------------------------------------------------------------------

CSS overview: 

	0. Reset
	1. Product Title
	2. Product Price
	3. Description
	4. Input Quantity
	5. Button
	6. Product Meta
	7. Tabs
	8. Additional Information
	9. On Sale
	10. Reviews Tab
	11. Cart
	12. Cart - Total
	13. Checkout
	14. My Account
	15. Register
  16. Menu
  17. Variations
  18. Widgets
  19. Widgets On Footer
  20. Account
  21. Zoom Gallery
	
  -------------------------------------------------------------------------------------*/



  /*-----------------------------------------------------------------------------------*/
/*  0.  Reset
/*-----------------------------------------------------------------------------------*/

/* Global */

.woocommerce #woocommerce-wrapper {
	padding: 60px 0;
}

/* Register/Login */

.mobile-mode.woocommerce .col2-set .col-1,
.mobile-mode.woocommerce .col2-set .col-2,
.mobile-mode.woocommerce-page .col2-set .col-1,
.mobile-mode.woocommerce-page .col2-set .col-2 {
  max-width: 100%;
}

/* Main Shop Product Price */

.woocommerce ul.products li.product .price {
	color: #222;
	display: flex;
}

.woocommerce ul.products li.product .price del {
	margin-right: 1em;
}

/* Main Shop Filter By Price */

.woocommerce .widget_price_filter .price_slider_wrapper .ui-widget-content {
	background-color: #777;
}

.woocommerce #respond input#submit.loading:after, 
.woocommerce a.button.loading:after, 
.woocommerce button.button.loading:after, 
.woocommerce input.button.loading:after {
	top: auto;
  right: 2em;
}

/* Main Shop Filter By Price Button */

.woocommerce .widget_price_filter .price_slider_amount .button {
  background-color: transparent !important;
  color: #212529 !important;
  padding: 7px 0!important;
  font-size: 1rem !important;
  transition: all 0.2s linear;
}

.woocommerce .widget_price_filter .price_slider_amount .button:hover {
  color: #545454 !important;
}

.woocommerce .widget_price_filter .ui-slider .ui-slider-handle,
.woocommerce .widget_price_filter .ui-slider-horizontal .ui-slider-range {
  z-index: inherit;
}


/* Main Shop Products */

.woocommerce h1.page-title {
	display: none;
}

.desktop-mode {
  &.woocommerce .site-main > ul.products li.product, &.woocommerce-page .site-main > ul.products li.product {
    margin: 0 1.8% 2.992em 0;
    width: 32.13%;
  }
  &.woocommerce .site-main > ul.products li.last, &.woocommerce-page .site-main > ul.products li.last {
    margin-right: 0;
  }
}

/* Main Shop Add to Cart Button */

.woocommerce .site-main > ul.products li.product .button {
	display: none;
}

/* Title Related & Upsells Products */

.woocommerce .products.related h2, 
.woocommerce .products.upsells h2 {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.5em;
  color: #222;
}

/* Pagination */

.woocommerce nav.woocommerce-pagination ul li a, 
.woocommerce nav.woocommerce-pagination ul li span {
  padding: 0.75em 1em;
}

.woocommerce nav.woocommerce-pagination ul {
  border-radius: 4px;
}

.woocommerce nav.woocommerce-pagination ul li:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.woocommerce nav.woocommerce-pagination ul li:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Ordering */

.woocommerce .woocommerce-ordering select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.75;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

/* Centered Menu Logo */

.wooccommerce,
.woocommerce-page {

  .centered-header .navbar-collapse.collapse.centered-header-menu .navbar-header .navbar-brand img {
    max-width: inherit;
  }

}

/* Font Weight Widgets */

.wooccommerce,
.woocommerce-page {

  ul.cart_list li a, 
  ul.product_list_widget li a {
    font-weight: 400;
  }

}

.wooccommerce .select2-container--default .select2-selection--single .select2-selection__arrow b,
.woocommerce-page .select2-container--default .select2-selection--single .select2-selection__arrow b {

  margin-top: -14px !important;

}

/*-----------------------------------------------------------------------------------*/
/*  1.  Product Title - Single Product
/*-----------------------------------------------------------------------------------*/

.woocommerce {
  div.product .product_title {
    font-weight: 900;
    font-size: 3rem;
    line-height: 1em;
    color: #222;
    margin-bottom: 3px;
  }
  #content div.product .product_title {
    margin-bottom: 3px;
  }
}

.woocommerce-page {
  div.product .product_title, #content div.product .product_title {
    margin-bottom: 3px;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  2.  Product Price - Single Product
/*-----------------------------------------------------------------------------------*/

.woocommerce .summary {
  .price {
    color: #444444 !important;
    font-size: 30px !important;
    line-height: 30px !important;
    letter-spacing: -1px;
    margin-top: 0.5em;
  }
  ins .price {
    color: #444444;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  3.  Description - Single Product
/*-----------------------------------------------------------------------------------*/

.woocommerce.woocommerce-page .woocommerce-tabs > div h2:first-child {
  display: none;
}

.woocommerce div.product .woocommerce-tabs .panel {
  background-color: transparent !important;
  width: 66.66667%;
  margin: 0 auto;
}

.woocommerce.woocommerce-page .woocommerce-tabs #tab-description > p:first-of-type:first-letter {
  font-size: 6em;
  font-weight: 700;
  float: left;
  line-height: 1;
  margin: 0 20px 0 0;
}

.woocommerce.woocommerce-page .woocommerce-tabs .entry-content p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 1em 0;
  color: #545454;
}

/*-----------------------------------------------------------------------------------*/
/*  4.  Input Quantity - Single Product
/*-----------------------------------------------------------------------------------*/

.woocommerce .cart .quantity input.qty {
  border-left: none;
  border-right: none;
  border-top: none;
  display: inline;
  line-height: 45px;
  margin: 0;
  padding: 0 0 0 9px;
  text-align: center;
  vertical-align: middle;
  width: 45px;
  position: relative;
  font-size: 24px !important;
  font-weight: 700;
  color: #545454;
}

/*-----------------------------------------------------------------------------------*/
/*  5.  Button - Single Product / Cart
/*-----------------------------------------------------------------------------------*/

.woocommerce a.button, .woocommerce-page a.button, .woocommerce button.button, .woocommerce-page button.button, .woocommerce #respond input#submit, .woocommerce-page #respond input#submit {
  background-color: #222 !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
  background-image: none !important;
  text-shadow: none !important;
  -moz-text-shadow: none !important;
  -webkit-text-shadow: none !important;
  -o-text-shadow: none !important;
  border: none !important;
  background-repeat: no-repeat;
  color: #FFFFFF !important;
  cursor: pointer;
  display: inline-block;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 15px;
  padding: 15px 40px !important;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: bottom 0.2s linear 0s, background-color 0.2s linear 0s;
}

.woocommerce {
  #content input.button {
    background-color: #222 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    box-shadow: none !important;
    background-image: none !important;
    text-shadow: none !important;
    -moz-text-shadow: none !important;
    -webkit-text-shadow: none !important;
    -o-text-shadow: none !important;
    border: none !important;
    background-repeat: no-repeat;
    color: #FFFFFF !important;
    cursor: pointer;
    display: inline-block;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 15px;
    padding: 15px 40px !important;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: bottom 0.2s linear 0s, background-color 0.2s linear 0s;
  }
  div.product form.cart .button {
    margin-left: 1.05em;
  }
}

.woocommerce-page div.product form.cart .button, .woocommerce #content div.product form.cart .button, .woocommerce-page #content div.product form.cart .button {
  margin-left: 1.05em;
}

.woocommerce ul.products li.product .button {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
}

//For Fix class Loading of Visual Elements flexslider
.woocommerce ul.products li.product .button.loading {
  min-height: inherit;
}
//End For Fix class Loading of Visual Elements flexslider

.woocommerce ul.products li.product:hover .button {
  display: block;
}

/*-----------------------------------------------------------------------------------*/
/*  6.  Product Meta - Single Product
/*-----------------------------------------------------------------------------------*/

.woocommerce div.product_meta {
  margin-bottom: 3em;
}

/*-----------------------------------------------------------------------------------*/
/*  7.  Tabs - Single Product
/*-----------------------------------------------------------------------------------*/

.woocommerce {
  .woocommerce-tabs.wc-tabs-wrapper {
    border-top: 1px solid #EEE;
    margin-bottom: 60px;
  }
  div.product .woocommerce-tabs ul.tabs li, #content div.product .woocommerce-tabs ul.tabs li {
    background-image: none !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    margin-left: -2px !important;
    margin-right: -2px !important;
    border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    transition: background-color 0.2s linear;
  }
}

.woocommerce-page {
  div.product .woocommerce-tabs ul.tabs li, #content div.product .woocommerce-tabs ul.tabs li {
    background-image: none !important;
    box-shadow: none !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -o-box-shadow: none !important;
    margin-left: -2px !important;
    margin-right: -2px !important;
    border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    -o-border-radius: 0px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    transition: background-color 0.2s linear;
  }
}

.woocommerce {
  div.product .woocommerce-tabs ul.tabs li:before, #content div.product .woocommerce-tabs ul.tabs li:before {
    box-shadow: none !important;
  }
}

.woocommerce-page {
  div.product .woocommerce-tabs ul.tabs li:before, #content div.product .woocommerce-tabs ul.tabs li:before {
    box-shadow: none !important;
  }
}

.woocommerce {
  div.product .woocommerce-tabs ul.tabs li {
    border-bottom: none !important;
    border-right: 0px !important;
    border-left: 0px !important;
    background-color: transparent !important;
    -webkit-transition: opacity 0.15s ease-in-out 0s;
    -moz-transition: opacity 0.15s ease-in-out 0s;
    -o-transition: opacity 0.15s ease-in-out 0s;
    transition: opacity 0.15s ease-in-out 0s;
    &:before, &:after {
      border: none !important;
      box-shadow: none !important;
    }
  }
  #content div.product .woocommerce-tabs ul.tabs li {
    &:before, &:after {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.woocommerce-page {
  div.product .woocommerce-tabs ul.tabs li {
    &:before, &:after {
      border: none !important;
      box-shadow: none !important;
    }
  }
  #content div.product .woocommerce-tabs ul.tabs li {
    &:before, &:after {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.woocommerce {
  div.product .woocommerce-tabs ul.tabs li.active, #content div.product .woocommerce-tabs ul.tabs li.active {
    background-color: #27CCC0;
    border-top: solid 3px #222 !important;
    color: #222 !important;
  }
}

.woocommerce-page {
  div.product .woocommerce-tabs ul.tabs li.active, #content div.product .woocommerce-tabs ul.tabs li.active {
    background-color: #27CCC0;
    border-top: solid 3px #222 !important;
    color: #222 !important;
  }
}

.woocommerce {
  div.product .woocommerce-tabs ul.tabs li:hover, #content div.product .woocommerce-tabs ul.tabs li:hover {
    border-top: solid 3px rgba(0, 0, 0, 0.6) !important;
  }
}

.woocommerce-page {
  div.product .woocommerce-tabs ul.tabs li:hover, #content div.product .woocommerce-tabs ul.tabs li:hover {
    border-top: solid 3px rgba(0, 0, 0, 0.6) !important;
  }
}

.woocommerce div.product .woocommerce-tabs {
  ul.tabs {
    padding-left: 2px !important;
    margin-bottom: 0px !important;
    text-align: center;
    &:before {
      border: none !important;
    }
    li a {
      font-weight: 700 !important;
      text-transform: uppercase;
      border-top: 0px !important;
      text-shadow: none !important;
      -moz-text-shadow: none !important;
      -webkit-text-shadow: none !important;
      -o-text-shadow: none !important;
      color: rgba(0, 0, 0, 0.35);
    }
  }
  .panel {
    box-shadow: none;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  8.  Additional Information - Single Product
/*-----------------------------------------------------------------------------------*/

.woocommerce table.shop_attributes, .woocommerce-page table.shop_attributes {
  margin-top: 13px;
}

.woocommerce table.shop_attributes tr td p, .woocommerce-page table.shop_attributes tr td p {
  margin: 10px 0 !important;
}

.woocommerce table.shop_attributes th, .woocommerce-page table.shop_attributes th, .woocommerce table.shop_attributes td, .woocommerce-page table.shop_attributes td {
  font-weight: normal !important;
  font-style: normal !important;
  border-right: none;
  background-color: transparent !important;
}

.woocommerce table.shop_attributes th, .woocommerce-page table.shop_attributes th {
  color: #333 !important;
}

/*-----------------------------------------------------------------------------------*/
/*  9.  On Sale
/*-----------------------------------------------------------------------------------*/

.woocommerce ul.products li.product .onsale, .woocommerce-page ul.products li.product .onsale, .woocommerce span.onsale, .woocommerce-page span.onsale {
  height: 18px;
  min-height: inherit;
  line-height: 18px;
  font-size: 10px;
  font-weight: 300;
  z-index: 1;
  letter-spacing: 1px;
  text-align: center;
  top: 0;
  position: absolute;
  outline: medium none;
  font-family: arial;
  color: #ffffff !important;
  text-transform: uppercase;
  margin: 10px;
  padding: 0 10px;
  background: #222 !important;
  left: 0;
  right: inherit;
  display: block;
  float: left;
  transition: all .2s linear;
  -moz-transition: all .2s linear;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  border-radius: 0;
}

.woocommerce-page .single-product-main-image .onsale {
  margin: 18px 10px;
}

/*-----------------------------------------------------------------------------------*/
/*  10.  Reviews Tab - Single Product
/*-----------------------------------------------------------------------------------*/

.woocommerce #reviews #comment {
	height: auto;
}

.woocommerce #reviews #comments ol.commentlist li .comment-text p, .woocommerce-page #reviews #comments ol.commentlist li .comment-text p {
  padding-bottom: 5px !important;
  font-size: 1rem !important;
  margin-bottom: 0px !important;
}

.woocommerce {
  .commentlist li .comment_container .comment-text {
    border: none !important;
    padding: 20px !important;
    margin-left: 70px !important;
  }
  #reviews #reply-title {
    margin: 30px 0px 15px 0px !important;
    text-transform: capitalize;
    margin: 0;
    padding: 25px 0;
    border-bottom: 1px solid #EBEDEF;
    font-size: 24px;
    line-height: 32px;
  }
  #pp_full_res .pp_inline #reply-title {
    margin-bottom: 18px;
  }
  #reviews #comments ol.commentlist li img.avatar {
    width: 45px !important;
    padding: 0px !important;
    background-color: transparent !important;
    height: 45px !important;
    left: 25px !important;
    top: 54px !important;
  }
}

.woocommerce-page #reviews #comments ol.commentlist li img.avatar {
  width: 45px !important;
  padding: 0px !important;
  background-color: transparent !important;
  height: 45px !important;
  left: 25px !important;
  top: 54px !important;
}

.woocommerce {
  .commentlist li .comment_container {
    margin: 2em 0 0;
  }
  .woocommerce-product-rating a {
    display: none;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  11.  Cart
/*-----------------------------------------------------------------------------------*/

.woocommerce .woocommerce-cart-form {
  @extend .col-md-8;
}

.woocommerce .cart-collaterals {
  @extend .col-md-4;
  padding: 0 !important;
}

.woocommerce table.cart {
	table-layout: auto;
}

.woocommerce table.cart th {
	font-weight: normal;
}

.woocommerce table.shop_table th, .woocommerce-page table.shop_table th, .woocommerce table.shop_table tfoot td, .woocommerce table.shop_table tfoot th, .woocommerce-page table.shop_table tfoot td, .woocommerce-page table.shop_table tfoot th {
  font-weight: 900 !important;
  text-transform: uppercase;
  color: #545454;
  border: none;
}

.woocommerce table.shop_table tr.cart_item td.product-name {
  font-weight: 700;
  line-height: 1.35;
  padding: 17px 0;
  color: #545454;
}

.woocommerce table.cart img, .woocommerce #content table.cart img, .woocommerce-page table.cart img, .woocommerce-page #content table.cart img {
	width: 60px!important;
	height: 60px!important;
}

.woocommerce table.shop_table, .woocommerce-page table.shop_table {
	border-radius: 0px!important;	
	-moz-border-radius: 0px!important;	
	-webkit-border-radius: 0px!important;	
	-o-border-radius: 0px!important;
	border: none!important;
}

.woocommerce table.cart td {
	padding: 30px 10px !important;
	border-right: none;
  border-bottom: none;
}

.woocommerce table.cart td.product-name a {
	color: #181818 !important;
}

.woocommerce table.cart td.product-name a:hover {
	color: rgba(0, 0, 0, 0.6) !important;
}

.woocommerce table.cart tr td:first-child {
	padding-left: 0px!important;
}

.woocommerce table.cart tr td:last-child {
	padding-right: 0px!important;
}

.woocommerce table.cart a.remove, .woocommerce #content table.cart a.remove, .woocommerce-page table.cart a.remove, .woocommerce-page #content table.cart a.remove {   
	transition: all 0.2s linear;
	font-size: 2rem !important;
	color: #181818 !important;
	width: 0.8em!important;
  height: 0.8em!important;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}

.woocommerce table.cart a.remove:hover, .woocommerce #content table.cart a.remove:hover, .woocommerce-page table.cart a.remove:hover, .woocommerce-page #content table.cart a.remove:hover {   
	background-color: transparent !important;
	color: rgba(0, 0, 0, 0.6) !important;
}


.woocommerce table.cart .product-thumbnail a, .woocommerce table.cart .product-thumbnail a img {
	display: block;
}

.woocommerce-page table.cart a.remove:hover {
	color: #fff!important;
}

.woocommerce .cart-collaterals .shipping_calculator, .woocommerce-page .cart-collaterals .shipping_calculator {
	margin-top: 0px!important;
}

.woocommerce-page input.button, 
.woocommerce .cart-table-vslmd .cart .button, 
.woocommerce .cart-table-vslmd .cart input.button {
	padding: 17px 40px !important;
  height: auto;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
  -moz-box-shadow: none!important;
  -webkit-box-shadow: none!important;
  -o-box-shadow: none!important;
  box-shadow: none!important;
  background-image: none!important;
  text-shadow: none!important;
  -moz-text-shadow: none!important;
  -webkit-text-shadow: none!important;
  -o-text-shadow: none!important;
  border: none!important;
  background-repeat: no-repeat;
  color: #FFFFFF !important;
  cursor: pointer;
  display: inline-block;
  font-size: 12px!important;
  font-weight: 700 !important;
  line-height: 15px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: bottom 0.2s linear 0s, background-color 0.2s linear 0s;
}

.woocommerce-page input.button:hover, 
.woocommerce .cart-table-vslmd .cart .button:hover, 
.woocommerce .cart-table-vslmd .cart input.button:hover {
	background-color: #222 !important;
}

.woocommerce input#coupon_code {
  width: 250px !important;
  margin: 0 10px 0 0;
  height: 46px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(84,84,84,0.1);
  font-weight: 400;
  padding: 13px 15px 13px 15px;
  outline: 0 !important;
  line-height: normal;
  float: left;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/*-----------------------------------------------------------------------------------*/
/*  12.  Cart - Total
/*-----------------------------------------------------------------------------------*/

.woocommerce .cart-collaterals-vslmd {
  background: rgba(84,84,84,0.05);
  padding: 30px 40px 40px;
}

.woocommerce .cart-collaterals-vslmd:before, 
.woocommerce .cart-collaterals-vslmd:after {
  background-image: radial-gradient(closest-side, transparent 9px, rgba(84,84,84,0.05) 100%);
  content: "";
  display: block;
  height: 10px;
  width: 100%;
  background-color: transparent;
  background-size: 20px 20px;
  background-position: 0 10px, 40px 40px;
}

.woocommerce .cart-collaterals-vslmd:before {
  position: absolute;
  top: -10px;
  left: 0;
}

.woocommerce .cart-collaterals-vslmd:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.woocommerce .cart-collaterals .cart_totals h2, 
.woocommerce-page .cart-collaterals .cart_totals h2 {
	color: #545454;
  border-bottom-color: rgba(84,84,84,0.15);
  padding: 0 0 20px;
  margin: 0 0 25px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  text-transform: uppercase;
  text-align: left;
  font-size: 15px;
  font-weight: 900;
  line-height: 1.13;
}

.woocommerce-cart .cart-collaterals .cart_totals table td {
	border-bottom: none;
}

.woocommerce .cart-collaterals .cart_totals table th, 
.woocommerce-page .cart-collaterals .cart_totals table th {
  padding: 9px 20px 5px 0;
  width: 50%;
  line-height: 1.2rem;
  font-weight: 400;
  color: #545454;
}

.woocommerce .cart-collaterals .cart_totals table th {
	padding-top: 0px;
  vertical-align: top;
}

.woocommerce .cart-collaterals .cart_totals, .woocommerce-page .cart-collaterals .cart_totals {
  width: 100% !important;
  float: none;
}

.woocommerce .cart-collaterals-vslmd tr.shipping ul#shipping_method label,
.woocommerce-page .cart-collaterals-vslmd tr.shipping ul#shipping_method label {
	display: block;
  text-transform: capitalize;
  font-weight: 400;
  color: #545454;
  font-size: 1rem;
  line-height: 1.3em;
  padding-top: 1px;
}

.woocommerce .cart-collaterals-vslmd tr.shipping ul#shipping_method input[type="radio"],
.woocommerce-page .cart-collaterals-vslmd tr.shipping ul#shipping_method input[type="radio"] {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 160px;
}

.woocommerce .cart-collaterals .cart_totals tr.order-total td, 
.woocommerce .cart-collaterals .cart_totals tr.order-total th, 
.woocommerce-page .cart-collaterals .cart_totals tr.order-total td, 
.woocommerce-page .cart-collaterals .cart_totals tr.order-total th {
  line-height: 1;
  padding-top: 25px;
  padding-bottom: 23px;
  vertical-align: middle;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(84,84,84,0.15);
  border-bottom-color: rgba(84,84,84,0.15);
}

.woocommerce .cart-collaterals .shipping-calculator-button, 
.woocommerce-page .cart-collaterals .shipping-calculator-button {
  display: inline-block;
  margin: 16px 0 0px;
  text-transform: uppercase !important;
  font-weight: 900;
  font-size: 0.8125rem;
}

.woocommerce .cart-collaterals-vslmd .cart_totals tr th,
.woocommerce-page .cart-collaterals-vslmd .cart_totals tr th{
	text-transform: capitalize;
	font-weight: 400 !important;
}

.woocommerce .cart-collaterals-vslmd .cart_totals tr.shipping th,
.woocommerce-page .cart-collaterals-vslmd .cart_totals tr.shipping th,
.woocommerce .cart-collaterals-vslmd .cart_totals tr.shipping td,
.woocommerce-page .cart-collaterals-vslmd .cart_totals tr.shipping td{
	border: none !important;
}

.woocommerce .cart-collaterals-vslmd tr.cart-subtotal th,
.woocommerce-page .cart-collaterals-vslmd tr.cart-subtotal th {
	line-height: 1.3;
}

.woocommerce .cart-collaterals-vslmd tr.cart-subtotal td,
.woocommerce-page .cart-collaterals-vslmd tr.cart-subtotal td {
	padding: 0 0 1.4em 0.8em;
}

.woocommerce .cart-collaterals-vslmd tr.cart-subtotal span.amount,
.woocommerce-page .cart-collaterals-vslmd tr.cart-subtotal span.amount {
	color: #545454;
}

.woocommerce .cart-collaterals .cart_totals tr.order-total td strong, 
.woocommerce-page .cart-collaterals .cart_totals tr.order-total td strong {
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  color: #545454;
}

.woocommerce .cart-collaterals .cart_totals .checkout-button,
.woocommerce-page .cart-collaterals .cart_totals .checkout-button {
  padding: 23px !important;
  line-height: initial;
  height: auto;
  width: 100%;
  margin: 20px 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.woocommerce .cart-collaterals .cart_totals .checkout-button:hover, 
.woocommerce-page .cart-collaterals .cart_totals .checkout-button:hover {
	opacity: 0.6;
}

/*-----------------------------------------------------------------------------------*/
/*  13.  Checkout
/*-----------------------------------------------------------------------------------*/

.woocommerce form .col-1,
.woocommerce form .col-2 {
  flex: auto;
  max-width: initial !important;
}

.woocommerce form .form-row input.input-text, 
.woocommerce form .form-row textarea,
.woocommerce .select2-selection {
	padding: 0 16px!important;
  width: 100%;
  display: block;
  border: 1px solid rgba(84,84,84,0.1) !important;
  line-height: 22px;
  color: #555 !important;
  background-color: transparent;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  height: 3rem;
  border-radius: 0;
}

.woocommerce form .password-input,
.woocommerce-page form .password-input {
  width: 100%;
}

.woocommerce form .form-row input.input-text:focus, 
.woocommerce form .form-row textarea:focus {
  color: #444;
  background-color: #fafafa;
}

.woocommerce #order_comments_field textarea {
  min-height: 100px;
  height: 100px;
  padding: 16px !important;
}

.select2-container {
  border: none;
  transition: unset !important;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0;
  margin: 0;
  font-weight: normal;
  opacity: 1;
  line-height: 2 !important;
  height: 40px !important;
  position: relative;
  zoom: 1;
}

.woocommerce .select2-selection span {
  height: 4.5rem !important;
  line-height: 44px !important;
  color: #757575 !important;
}

.select2-dropdown {
	border: 1px solid rgba(84,84,84,0.1) !important;
  border-radius: 0;
  transition: none;
}

.select2-search {
  background: #fafafa;
}

.woocommerce form .form-row label, 
.woocommerce-page form .form-row label {
	color: #545454;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 1px;
  line-height: 2em;
  width: 100%;
}

.woocommerce form h3 {
	font-size: 1.3rem;
	margin: 0px 20px 20px 0;
	text-transform: uppercase;
	font-weight: 900;
	color: #545454;
}

.woocommerce form h3#order_review_heading {
	display: none;
}

.woocommerce form label.checkbox {
	padding-left: 0px !important;
	display: initial;
	margin-right: 30px;
}

.woocommerce .woocommerce-checkout .col2-set .col-1, 
.woocommerce-page .woocommerce-checkout .col2-set .col-1,
.woocommerce .woocommerce-checkout .col2-set .col-2, 
.woocommerce-page .woocommerce-checkout .col2-set .col-2 {
	float: none !important;
	width: auto !important;
}

.woocommerce .woocommerce-checkout .col2-set .col-2, 
.woocommerce-page .woocommerce-checkout .col2-set .col-2 {
	margin-top: 50px !important;
}

.woocommerce .checkout-order-total-vslmd {
  background: rgba(84,84,84,0.05);
  padding: 30px 40px 40px;
}

.woocommerce .checkout-order-total-vslmd:before, 
.woocommerce .checkout-order-total-vslmd:after {
  background-image: radial-gradient(closest-side, transparent 9px, rgba(84,84,84,0.05) 100%);
  content: "";
  display: block;
  height: 10px;
  width: 100%;
  background-color: transparent;
  background-size: 20px 20px;
  background-position: 0 10px, 40px 40px;
}

.woocommerce .checkout-order-total-vslmd:before {
  position: absolute;
  top: -10px;
  left: 0;
}

.woocommerce .checkout-order-total-vslmd:after {
  position: absolute;
  bottom: -10px;
  left: 0;
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.woocommerce-checkout #payment div.payment_box {
	background-color: transparent !important;
}

.woocommerce-checkout #payment div.payment_box:before {
	border: none !important;
}

.woocommerce .checkout-order-total-vslmd table.shop_table th, 
.woocommerce-page .checkout-order-total-vslmd table.shop_table th {
  padding: 17px 0;
  width: 50%;
}

.woocommerce .checkout-order-total-vslmd table.shop_table tr.cart-subtotal th, 
.woocommerce-page .checkout-order-total-vslmd table.shop_table tr.cart-subtotal th,
.woocommerce .checkout-order-total-vslmd table.shop_table tr.shipping th, 
.woocommerce-page .checkout-order-total-vslmd table.shop_table tr.shipping th,
.woocommerce .checkout-order-total-vslmd table.shop_table tr.order-total th, 
.woocommerce-page .checkout-order-total-vslmd table.shop_table tr.order-total th {
  text-transform: capitalize !important;
  font-weight: 400 !important;
  line-height: 1.2rem;
}

.woocommerce table.shop_table tr.cart_item td.product-name {
	font-weight: 700;
	line-height: 1.35;
  padding: 17px 0;
  color: #545454;
}

.woocommerce table.shop_table tr.cart_item td.product-total,
.woocommerce table.shop_table tr.cart-subtotal td {
  color: #545454;
  font-weight: 400 !important;
}

.woocommerce .checkout-order-total-vslmd table.shop_table tr.shipping td ul li label, 
.woocommerce-page .checkout-order-total-vslmd table.shop_table tr.shipping td ul li label {
	font-weight: 500 !important;
	text-transform: capitalize !important;
	font-size: 0.89rem;
  line-height: 1.3em;
}

.woocommerce .checkout-order-total-vslmd table.shop_table tr.shipping td ul li input, 
.woocommerce-page .checkout-order-total-vslmd table.shop_table tr.shipping td ul li input {
  left: -25px;
  position: relative;
  top: 2px;
}

.woocommerce-checkout #payment ul.payment_methods li label {
  display: initial;
	font-size: 1.125rem;
  margin: 1px 0 12px;
  text-transform: capitalize;
  color: #545454;
}

.woocommerce-checkout #payment .payment_method_paypal .about_paypal {
	display: none;
}

.woocommerce #payment #place_order, 
.woocommerce-page #payment #place_order {
  padding: 23px !important;
  line-height: initial;
  height: auto;
  width: 100%;
  margin: 20px 0;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.woocommerce #payment #place_order:hover, 
.woocommerce-page #payment #place_order:hover {
  opacity: 1;
}

.woocommerce #payment ul.payment_methods li, 
.woocommerce-page #payment ul.payment_methods li {
	list-style: none!important;
	border-bottom: 1px solid rgba(84,84,84,0.15);
	margin: 13px 0 0 !important;
}

.woocommerce #payment ul.payment_methods li:last-child, 
.woocommerce-page #payment ul.payment_methods li:last-child,
.woocommerce-checkout #payment ul.payment_methods {
  border-bottom: 0;
}

.woocommerce .payment_box p {
	padding-bottom: 0px!important;
	padding-left: 21px;
	font-size: 12px!important;
	margin: -20px 0 0 0;
	padding: 1em 0 1em 1em;
}

.woocommerce-checkout #payment ul.payment_methods {
	padding: 0;
}

.woocommerce .payment_box {
	margin: 0px!important;
	background-image: none!important;
	box-shadow: none!important;
	-moz-box-shadow: none!important;
	-webkit-box-shadow: none!important;
	-o-box-shadow: none!important;
}

.woocommerce .payment_box:after {
	border: none!important;
}

.woocommerce .payment_box p {
	color: rgba(84,84,84,0.55);
}

.woocommerce-page .shop_table.woocommerce-checkout-review-order-table tfoot .order-total .amount {
  font-size: 1.5rem;
  font-weight: 700;
}

.woocommerce table.woocommerce-checkout-review-order-table td {
  border-top: none;
  border-right: none;
}

.woocommerce #payment, html .woocommerce-page #payment {
  background-color: transparent!important;
}

.woocommerce table.woocommerce-checkout-review-order-table tfoot tr.order-total th,
.woocommerce table.woocommerce-checkout-review-order-table tfoot tr.order-total td {
	border-top: 1px solid rgba(0,0,0,.1);
}

.woocommerce .woocommerce-input-wrapper {
  width: 100%;
}

/* Order Received */

.woocommerce table.shop_table td {
  border-right: 0;
  border-bottom: 0;
}

/*-----------------------------------------------------------------------------------*/
/*  14.  My Account
/*-----------------------------------------------------------------------------------*/

.woocommerce nav.woocommerce-MyAccount-navigation ul {
  padding-left: 0;
  margin-bottom: 20px;
}

.woocommerce nav.woocommerce-MyAccount-navigation ul li:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.woocommerce nav.woocommerce-MyAccount-navigation ul li:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.woocommerce nav.woocommerce-MyAccount-navigation ul li {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.woocommerce nav.woocommerce-MyAccount-navigation ul li a {
	color: #555;
}

.woocommerce nav.woocommerce-MyAccount-navigation ul li:focus, 
.woocommerce nav.woocommerce-MyAccount-navigation ul li:hover {
  text-decoration: none;
  background-color: #f5f5f5;
}

.woocommerce nav.woocommerce-MyAccount-navigation ul li.is-active, 
.woocommerce nav.woocommerce-MyAccount-navigation ul li.is-active:focus, 
.woocommerce nav.woocommerce-MyAccount-navigation ul li.is-active:hover {
  background-color: #eee;
  border-color: #ddd;
}

.woocommerce nav.woocommerce-MyAccount-navigation ul li.is-active a, 
.woocommerce nav.woocommerce-MyAccount-navigation ul li.is-active:focus a, 
.woocommerce nav.woocommerce-MyAccount-navigation ul li.is-active:hover a {
  color: #777;
}

/*-----------------------------------------------------------------------------------*/
/*  15.  Register
/*-----------------------------------------------------------------------------------*/

.woocommerce .login .form-row label.inline {
	margin-left: 2em;
	vertical-align: -webkit-baseline-middle;
}

/*-----------------------------------------------------------------------------------*/
/*  16.  Menu
/*-----------------------------------------------------------------------------------*/

/* Cart Content Count */

.cart-content-count.cart-content-count-null {
  visibility: hidden;
}

/* Cart Menu */

.nav-cart span {
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
}

.cart-menu {
  ul {
    &.cart_list li, &.product_list_widget li {
      padding: 4px 0;
      margin: 0;
      list-style: none;
    }
    &.cart_list li a, &.product_list_widget li a {
      display: block;
      font-weight: 700;
    }
    &.cart_list, &.product_list_widget {
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  div.widget_shopping_cart_content ul.product_list_widget li a {
    line-height: 1 !important;
    height: auto !important;
  }
  a.remove {
    font-size: 1.5em;
    text-align: center;
    border-radius: 100%;
    text-decoration: none;
    border: 0;
    width: 0;
  }
  div.widget_shopping_cart_content p {
    &.woocommerce-mini-cart__empty-message {
      margin: 0;
    }
    &.total {
      padding-top: 10px;
      margin-top: 20px;
      text-align: right;
    }
    &.buttons a.button.wc-forward {
      height: auto;
      line-height: 1;
      padding: 1.2em 0;
      display: block;
      text-align: center;
      margin-top: 2rem;
      font-weight: 700;
      border-radius: 3px;
    }
  }
  ul {
    &.cart_list li {
      &:after, &:before {
        content: " ";
        display: table;
      }
    }
    &.product_list_widget li {
      &:after, &:before {
        content: " ";
        display: table;
      }
    }
    &.cart_list li img, &.product_list_widget li img {
      width: 64px;
      margin-top: -21px;
      float: right;
      height: auto;
      box-shadow: none;
    }
  }
}

.desktop-mode {
  .navbar-light .cart-menu,
  .nav-cart .dropdown-menu-light-no-bg .cart-menu {
    .woocommerce-mini-cart__empty-message {
      color: rgba(0, 0, 0, 0.5);
    }
    .widget_shopping_cart_content {
      ul.product_list_widget li {
        color: #343a40;
        a {
          color: #343a40 !important;
          transition: all 0.2s ease-in-out;
          &:hover {
            color: rgba(52, 58, 64, 0.3) !important;
          }
          &.remove {
            color: rgba(52, 58, 64, 0.3) !important;
            transition: all 0.2s ease-in-out;
            &:hover {
              color: #343a40 !important;
            }
          }
        }
      }
      p {
        &.total {
          color: #343a40;
          border-top: 1px solid rgba(52, 58, 64, 0.3);
        }
        &.buttons a.button.wc-forward {
          @extend .text-white;
          @extend .bg-secondary;
        }
      }
    }
  }
}

.desktop-mode {
  .navbar-dark .cart-menu,
  .nav-cart .dropdown-menu-dark-no-bg .cart-menu {
    .woocommerce-mini-cart__empty-message {
      color: rgba(255, 255, 255, 0.5);
    }
    .widget_shopping_cart_content {
      ul.product_list_widget li {
        color: #FFF;
        a {
          color: #FFF !important;
          transition: all 0.2s ease-in-out;
          &:hover {
            color: rgba(255, 255, 255, 0.3) !important;
          }
          &.remove {
            color: rgba(255, 255, 255, 0.3) !important;
            transition: all 0.2s ease-in-out;
            &:hover {
              color: #FFF !important;
            }
          }
        }
      }
      p {
        &.total {
          color: #FFF;
          border-top: 1px solid rgba(255, 255, 255, 0.3);
        }
        &.buttons a.button.wc-forward {
          @extend .text-dark;
          @extend .bg-light;
        }
      }
    }
  }
  .dropdown-menu.dropdown-menu-dark-no-bg .cart-menu .woocommerce-mini-cart__empty-message {
    color: rgba(255, 255, 255, 0.5);
  }
}


/*-----------------------------------------------------------------------------------*/
/*  17.  Variations
/*-----------------------------------------------------------------------------------*/

.woocommerce .variations {
  td.label label {
    color: #222;
    font-size: 1.5rem;
  }
  td.value select {
    @extend .btn;
    @extend .btn-secondary;
    @extend .btn-block;
    @extend .dropdown-toggle;
  }
}

/*-----------------------------------------------------------------------------------*/
/*  18.  Widgets
/*-----------------------------------------------------------------------------------*/

.woocommerce, .woocommerce-page {

  /* Product Categories */

  .product-categories {
    list-style: none;
    margin: 0 0 3.5em;
    li {
      padding: .25em 0;
    }
  }

  /* Recent Comments */

  #recentcomments {
    list-style: none;
    margin: 0 0 3.5em;
    li {
      padding: .25em 0;
    }
  }

  /* Search Products */

  .woocommerce-product-search {
    display: flex;

    input.search-field {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      display: block;
      width: 100%;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.75;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem 0 0 .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    button {
      border-radius: 0 .25rem .25rem 0;
      cursor: pointer;
      transition: all .2s linear;
      -moz-transition: all .2s linear;
      -webkit-transition: all .2s linear;
      color: #fff;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      user-select: none;
      border: 1px solid transparent;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.75;
      -webkit-appearance: button;
      overflow: visible;
      margin: 0;
      font-family: inherit;
      &:hover {
        background-color: #222328;
      }
    }

  }

  /* Cart */

  .widget_shopping_cart_content {
    .woocommerce-mini-cart__buttons {
      display: flex;
      flex-direction: column;
      a {
        margin-right: 0;
      }
    }
  }

}

/*-----------------------------------------------------------------------------------*/
/*  19.  Widgets On Footer
/*-----------------------------------------------------------------------------------*/

footer.site-footer ul.product_list_widget {

  li a {
    display: block;
    font-weight: 700;

    img {
      float: right;
      margin-left: 4px;
      width: 32px;
      height: auto;
      box-shadow: none;
    }

  }

}

/*-----------------------------------------------------------------------------------*/
/*  20.  Account
/*-----------------------------------------------------------------------------------*/

/* Addresses Tab */

.woocommerce .col2-set .col-1, 
.woocommerce-page .col2-set .col-1,
.woocommerce .col2-set .col-2, 
.woocommerce-page .col2-set .col-2 {
  @extend .col-md-6;
}

/*-----------------------------------------------------------------------------------*/
/*  21.  Zoom Galery
/*-----------------------------------------------------------------------------------*/

.woocommerce div.product div.images .woocommerce-product-gallery__trigger {
  z-index: 1 !important;
}