/* Side Navigation */

.desktop-mode {
  .side-navigation {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    overflow: hidden;
    transition: .5s;
    .navbar-vertical li a {
      span {
        font-size: 1.1rem;
        line-height: 1em;
      }
      div {
        font-size: 1.1rem;
        line-height: 1em;
        &.title-content span.item-description {
          display: block;
          font-size: 1.1rem;
          line-height: 2em;
        }
      }
    }
    &#sidenav-fullscreen li a {
      span {
        font-size: 2rem;
        line-height: 1.3em;
      }
      div {
        font-size: 2rem;
        line-height: 1.3em;
        &.title-content span.item-description {
          display: block;
          font-size: 1.3rem;
          line-height: 3em;
        }
      }
    }
    .side-navigation-inner {
      height: 100%;
      width: 100%;
      padding: 4em 2em 0;
    }
  }
  &.admin-bar .side-navigation,
  &.admin-bar .side-navigation .closebtn {
    top: 32px;
  }
  .side-navigation {
    .closebtn {
      position: absolute;
      top: 0;
      right: 25px;
      font-size: 36px;
      margin-left: 50px;
    }
    &.light .closebtn {
      color: rgba(0, 0, 0, 0.6);
      &:hover {
        color: #000;
      }
    }
    &.dark .closebtn {
      color: rgba(255, 255, 255, 0.6);
      &:hover {
        color: #fff;
      }
    }
  }
}

.mobile-mode {

  .side-navigation {
    display: none;
  }

}