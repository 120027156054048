.tt_event_theme_page {
  @extend .container;
}

.tt_event_page_left {
  @extend .col-md-8;
  @media (min-width: 768px) {
    flex: 0 0 61.66667%;
    max-width: 61.66667% !important;
  }
}

.tt_event_page_right {
  @extend .col-md-4;
  @media (min-width: 768px) {
    flex: 0 0 28.33333%;
    max-width: 28.33333% !important;
  }
}